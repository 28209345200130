import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import Link from "components/Link";
import Image from "components/Image";
import { handleHit } from "../../utils/handleHit";

const Card16Podcast = ({ className = "h-full", post, ratio = "aspect-square", type = "", isShowContent = true }) => {
    const { title, href, category, desc, featuredImage, viewdCount, commentCount, kod } = post;

    return (
        <Link href={href} onClick={() => handleHit(kod)} className={`nc-Card16Podcast relative flex flex-col ${className}`}>
            <div>
                <h3 className="text-xl ml-4">{category}</h3>
            </div>

            <Image
                fill
                alt=""
                src={featuredImage}
                className="!max-w-full !h-auto relative !object-contain max-h-96"
            />
            {/* <span className="bg-neutral-900 bg-opacity-30"></span> */}

            {/* MAIN CONTENT */}
            {isShowContent && (
                <div className="w-12/12 transform flex flex-col min-h-32">
                    {/* <div className="w-11/12 transform -mt-32">  */}
                    <div
                        className={`p-5 bg-white dark:bg-neutral-900 ${type === "slider" ? "" : "shadow-xl dark:shadow-2xl"} rounded-3xl rounded-t-none flex flex-col flex-grow`}>
                        <h2 className={`nc-card-title block ${type === "slider" ? "text-md" : "sm:text-lg lg:text-xl"} ${type === "slider" && ""} font-semibold text-neutral-900 dark:text-neutral-100`}>
                            <div className="line-clamp-3" title={title}>
                                {title}
                            </div>
                        </h2>

                        <span className={`block text-sm text-neutral-500 dark:text-neutral-400 ${type === "slider" && ""}`}>
                            <span className="line-clamp-2">{desc}</span>
                        </span>

                        <div className="flex items-end justify-between mt-auto">
                            <PostCardLikeAndComment likeCount={viewdCount} commentCount={commentCount} href={href}
                                className="relative" />
                        </div>
                    </div>
                </div>
            )}
        </Link>
    );
};

export default Card16Podcast;
