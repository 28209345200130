import Swal from 'sweetalert2'

export function readMessage(content, title = "Yazar Bilgisi") {

    // let text = new DOMParser().parseFromString(content, 'text/html').body.textContent
    // text = text.replace("&nbps;", "");

    const swalWithCustomButtons = Swal.mixin({
        customClass: {
            confirmButton: "h-8 bg-indigo-50 text-indigo-600 mx-2 px-8 text-md rounded-md hover:bg-indigo-100 !outline-indigo-500 border-2 !border-indigo-100",
        },
        buttonsStyling: false
    });

    swalWithCustomButtons.fire({
        size: "sm",
        title: title,
        html: `<div class="flex flex-col gap-4 items-center justify-center"> ${content} </div>`,
        showCancelButton: false,
        confirmButtonText: "Kapat",
        reverseButtons: true,
    })
    return false;
}