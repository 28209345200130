import { useEffect, useState } from "react";
import SectionAds from "../../components/Sections/SectionAds";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import { fetchAPI } from "../../hooks/fetchApı";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import SectionSliderPosts from "../../components/Sections/SectionSliderPosts";
import SectionLargeSlider from "../../app/(home)/SectionLargeSlider";
import SectionMagazine2 from "../../components/Sections/SectionMagazine2";
import Card16Podcast from "../../components/Card16Podcast/Card16Podcast";
import Card15Podcast from "../../components/Card15Podcast/Card15Podcast";
import Loading from "../../components/Loading";
import SectionGridPosts from "./SectionGridPosts";
import CurrencyComponent from "./CurrencyComponent";
import { decodeHTMLJson } from "../../utils/decodeHTML";
import BreakingNewsComponent from "./BreakingNewsComponent";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { Link } from "react-router-dom";

const Home = () => {
    const [home1, setHome1] = useState([]);
    const [home2, setHome2] = useState([]);
    const [home3, setHome3] = useState([]);
    const [home4, setHome4] = useState([]);
    const [home5, setHome5] = useState([]);
    const [home6, setHome6] = useState([]);
    const [celebrationNews, setCelebrationNews] = useState([]);
    const [sportsNews, setSportsNews] = useState([]);
    const [economy, setEconomy] = useState({});
    const [ads, setAds] = useState([]);
    const [breakingNews, setBreakingNews] = useState([]);
    const [loading, setLoading] = useState(true);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();
    useEffect(() => {
        async function fetchData() {
            try {
                // Home verilerini tek bir API çağrısında almak
                const homeData = await fetchAPI('home', createFormData, sendRequest);

                // home-1 data
                const home1Data = homeData.home_1.map(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                    return item;
                });
                setHome1(home1Data);

                // home-2 data
                const home2Data = homeData.home_2.map(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                    return item;
                });
                setHome2(home2Data);

                // home-3 data
                const home3Data = homeData.home_3.map(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                    return item;
                });
                setHome3(home3Data);

                // home-4 data
                const home4Data = homeData.home_4.map(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                    return item;
                });
                setHome4(home4Data);

                // home-5 data
                const sportsData = homeData.spor_haberleri.map(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                    return item;
                });
                setSportsNews(sportsData);

                // home-5 data
                const home5Data = homeData.home_5.map(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                    return item;
                });
                setHome5(home5Data);

                // home-6 data
                const home6Data = homeData.home_6.map(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                    return item;
                });
                setHome6(home6Data);

                // home-6 data
                const breakingNewsData = homeData.breakingNews.map(item => {
                    item.href = '/' + item.href;
                    return item;
                });
                setBreakingNews(breakingNewsData);

                const celebrationData = await fetchAPI('celebrations', createFormData, sendRequest, 0, 10);
                celebrationData.news.forEach(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.featuredImage;
                });
                setCelebrationNews(celebrationData.news)

                const adsData = await fetchAPI('getAds', createFormData, sendRequest, null, null, 48);
                adsData.forEach(item => {
                    item.imgAds = 'https://avrupaninsesi.com/isDosyalar/' + item.yol + "/" + item.url;
                });
                setAds(adsData);

                const services = await fetchAPI(
                    'getServices',
                    createFormData,
                    sendRequest,
                    null,
                    null,
                    "'gasoline','currency','gold','cripto'"
                );

                // 0 - curreny
                const currencyData = {
                    updatedDate: services[0]?.ekleme_tarihi,
                    data: decodeHTMLJson(services[0]?.gelen_veri).data
                };

                // 1 - gold
                const goldData = {
                    updatedDate: services[1]?.ekleme_tarihi,
                    data: decodeHTMLJson(services[1]?.gelen_veri).data
                };

                // 2 - cripto
                const cryptoData = {
                    updatedDate: services[2]?.ekleme_tarihi,
                    data: decodeHTMLJson(services[2]?.gelen_veri).data
                };

                // 3 - gasoline
                const gasolineData = {
                    updatedDate: services[3]?.ekleme_tarihi,
                    data: decodeHTMLJson(services[3]?.gelen_veri).data
                };

                if (Object.keys(currencyData).length !== 0 && Object.keys(goldData).length !== 0) {
                    setEconomy({ currencyData, goldData, cryptoData, gasolineData });
                }

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data " + error);
                setLoading(false);
            }
        }

        fetchData();
    }, [createFormData, sendRequest]);

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <div className="nc-PageHome relative">
                    <div className=" relative">

                        {ads[0] && (
                            <div className="container my-4">
                                <SectionAds link={ads[0].link} imgAds={ads[0].imgAds} title={ads[0].baslik} />
                            </div>
                        )}

                        <section className="container flex flex-col gap-y-2.5 my-4">
                            {breakingNews.length > 0 && (
                                <BreakingNewsComponent data={breakingNews} />
                            )}
                            <CurrencyComponent data={economy} />
                        </section>

                        {/*haberler slider*/}
                        <section className="container relative my-4">
                            <BackgroundSection />
                            <SectionSliderPosts
                                posts={home1}
                                card="Card16"
                            />

                        </section>

                        {ads[1] && (
                            <div className="container my-4">
                                <SectionAds link={ads[1].link} imgAds={ads[1].imgAds} title={ads[1].baslik} />
                            </div>
                        )}

                        <section className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100 rounded-lg py-20">
                            <div className="relative container">
                                <SectionLargeSlider
                                    heading=""
                                    className=""
                                    posts={home2}
                                />
                            </div>
                        </section>

                        {celebrationNews.length > 0 && (
                            <section className="container relative my-4">
                                <BackgroundSection />
                                <SectionSliderPosts
                                    posts={celebrationNews}
                                    perView={2}
                                    autoplay={false}
                                    isShowContent={false}
                                    card="Card16"
                                />
                                <div className="flex mt-20 justify-center items-center">
                                    <Link to="/kutlamalar">  <ButtonPrimary>Tümünü Gör</ButtonPrimary></Link>
                                </div>
                            </section>
                        )}


                        {ads[2] && (
                            <div className="container  my-4">
                                <SectionAds link={ads[2].link} imgAds={ads[2].imgAds} title={ads[2].baslik} />
                            </div>
                        )}

                        {/*gündem haberleri*/}
                        <section className="container relative">
                            <BackgroundSection />
                            <SectionMagazine2
                                className=""
                                heading=""
                                posts={home3}
                                showHeader={false}
                            />
                        </section>

                        {ads[3] && (
                            <div className="container my-4">
                                <SectionAds link={ads[3].link} imgAds={ads[3].imgAds} title={ads[3].baslik} />
                            </div>
                        )}

                        {/*karışık haberler*/}
                        <section className="container">
                            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
                                {home4.map((post, key) => (
                                    <Card16Podcast key={key} post={post} />
                                ))}
                            </div>
                        </section>

                        {ads[4] && (
                            <div className="container my-4">
                                <SectionAds link={ads[4].link} imgAds={ads[4].imgAds} title={ads[4].baslik} />
                            </div>
                        )}

                        {/*alt haber başlıkları*/}
                        <section className="container relative my-4">
                            <BackgroundSection />
                            <div className="md:col-span-2 lg:col-span-3 py-4">
                                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
                                    {home5.map((p, key) => (
                                        <Card15Podcast key={key} post={p} />
                                    ))}
                                </div>
                            </div>
                        </section>

                        {ads[5] && (
                            <div className="container my-4">
                                <SectionAds link={ads[5].link} imgAds={ads[5].imgAds} title={ads[5].baslik} />
                            </div>
                        )}

                        <section className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100 rounded-lg">
                            <div className="relative container">
                                <SectionGridPosts
                                    className="py-4"
                                    headingIsCenter
                                    postCardName="card10V2"
                                    heading="Video Galeri"
                                    buttonLink="/video-galeri"
                                    subHeading=""
                                    posts={home6}
                                    gridClass="md:grid-cols-2 lg:grid-cols-3"
                                />
                            </div>
                        </section>

                        {ads[6] && (
                            <div className="container my-4">
                                <SectionAds link={ads[5].link} imgAds={ads[5].imgAds} title={ads[5].baslik} />
                            </div>
                        )}

                        <section className="relative container">
                            <SectionGridPosts
                                className="py-16 lg:py-28"
                                headingIsCenter
                                postCardName="card10V2"
                                heading="Spor Haberleri"
                                buttonLink="kategori/spor"
                                subHeading=""
                                posts={sportsNews}
                                gridClass="md:grid-cols-2 lg:grid-cols-3"
                            />
                        </section>
                    </div>
                </div>
            )}
        </>
    )
}

export default Home;
